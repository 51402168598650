<template>
  <div class="partner-api-list">
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <!-- TODO: Pharse 2 implement export excel -->
            <b-dropdown size="sm" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="handleExcelModal">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="ml-3">
            <router-link :to="addWebPartnerPath">
              <b-button variant="primary" size="sm" class="font-weight-bolder">
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </b-button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row>
              <b-col class="pl-0">
                <b-input
                  size="sm"
                  placeholder="Nhập tên cấu hình"
                  v-model="paramsFilter.name"
                  v-on:input="onChange($event, 'name')"
                ></b-input>
              </b-col>

              <b-col> </b-col>
              <b-col></b-col>
              <b-col></b-col>
            </b-row>
          </b-form>
        </div>

        <b-table
          :items="partnerApiList"
          :fields="partnerApiFields"
          :busy="onLoading"
          bordered
          hover
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(id)="row">
            <div>
              <span
                v-text="row.item.id"
                class="d-flex justify-content-center"
              ></span>
            </div>
          </template>
          <template v-slot:cell(createdAt)="row">
            <div>
              <span
                v-text="row.item.createdAt"
                class="d-flex justify-content-center"
              ></span>
            </div>
          </template>
          <template v-slot:cell(status)="data">
            <span
              v-if="data.item.status"
              v-text="data.item.statusName"
              class="label font-weight-bold label-lg label-inline"
              :class="statusClasses(data.item.status)"
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="editPartnerApiById(row.item.id)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>

                <!-- @TODO: v-if="checkPermission('PARTNER_API_DELETE')" -->
                <b-dropdown-item @click="handleItemDetete(row.item.id)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-show="hasPagination"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="getPartnerApis()"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1"
              last-class="page-item-last btn btn-icon btn-sm m-1"
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import { VclTable } from 'vue-content-loading';

import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';

import { TIME_OUT } from '@/utils/constants';
import { PARTNER_API_STATUS } from '@/utils/enum';

import KTCodePreview from '@/view/content/CodePreview.vue';

export default {
  components: {
    KTCodePreview,
    VclTable,
  },
  data() {
    return {
      addWebPartnerPath: '/web-partners/add-web-partner',
      title: '',
      paramsFilter: {
        name: null,
      },
      partnerApiList: [],
      installments: [],
      onLoading: false,
      numberOfPage: 1,
      totalItems: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      partnerApiFields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'name',
          label: 'Tên cấu hình',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdByName',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '' },
      ],
    };
  },
  computed: {
    ...mapGetters(['breadcrumbs']),
    hasPagination() {
      return this.numberOfPage >= 2;
    },
  },
  mounted() {
    this.title = 'Danh sách cấu hình web gateway';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.getPartnerApis();
  },
  methods: {
    async getPartnerApis() {
      this.page = this.$route.query.page || 1;

      const params = this.getFilterParams();

      ApiService.query('/partner-apis', { params }, { timeout: TIME_OUT }).then(
        (response) => {
          const dataset = response.data.data;
          this.totalItems = dataset.total;
          this.numberOfPage = dataset.total_page;
          this.partnerApiList = dataset.data;
          this.onLoading = false;
        },
      );
    },
    statusClasses(status) {
      return status === PARTNER_API_STATUS.ACTIVE
        ? 'label-light-success'
        : 'label-light-danger';
    },
    editPartnerApiById(partnerApiId) {
      this.$router.push({
        name: 'edit-web-partner',
        params: { partnerApiId },
      });
    },
    getFilterParams() {
      const name = this.paramsFilter.name
        ? this.paramsFilter.name.trim()
        : null;
      return {
        page: this.$route.query.page || 1,
        size: this.size || 10,
        name,
      };
    },
    deletePartnerApi(id) {
      // @TODO: delete partner api
      console.log(
        '🚀 ~ file: WebPartnerList.vue ~ line 290 ~ deletePartnerApi ~ id',
        id,
      );
      alert('Tính năng đang phát triển');
    },
    handleItemDetete(item) {
      Swal.fire({
        title: 'Xóa cấu hình web gateway!',
        text: 'Bạn có chắc muốn xóa cấu hình web gateway này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePartnerApi(item);
        }
      });
    },
    handleExcelModal() {
      alert('Tính năng đang phát triển');
    },
    onChange(textInput = '', type) {
      // @TODO: make filter by text input

      switch (type) {
        case 'name': {
          if (!textInput || !textInput.trim().length) {
            return;
          }
          break;
        }
        default:
          break;
      }
    },
    searchPartnerApis(textInput, keyParamSearch) {
      this.paramsFilter[keyParamSearch] = textInput;
      this.getPartnerApis();
    },
  },
};
</script>

<style lang="scss" scoped>
.partner-api-list {
  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
